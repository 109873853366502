import { IUser } from 'types/user';
import { faker } from '@faker-js/faker';
import { IResponseCurrentUser } from 'api/getCurrentUser/index';
import { questionnaireIdMock } from 'api/utils';

export function createRandomUser(): IUser {
  return {
    id: 'user_id_1',
    name: faker.person.fullName(),
    email: faker.internet.email(),
    features: {
      allow_answering: true,
    },
    user_permissions: [
      {
        org: 'assessor',
        role: 'owner',
        questionnaire_id: questionnaireIdMock,
      },
      {
        org: 'assessor',
        role: 'co_owner',
        questionnaire_id: 'questionnaire_0',
      },
      {
        org: 'assessor',
        role: 'admin',
        questionnaire_id: 'questionnaire_5',
      },
      {
        org: 'vendor',
        role: 'contributor',
        questionnaire_id: questionnaireIdMock,
        vendor_name: faker.person.fullName(),
      },
      {
        org: 'vendor',
        role: 'contact',
        questionnaire_id: 'questionnaire_0',
        vendor_name: faker.person.fullName(),
      },
    ],
  };
}

export const getCurrentUserMock: IResponseCurrentUser = createRandomUser();
