import { API_HOST, AUTH0_AUDIENCE } from 'utils/constants';
import { ResponseError } from 'types/errors';
import { auth0 } from './auth0';
import { getHex } from './common';

import * as Sentry from '@sentry/react';

class SentryError extends Error {
  constructor(response: Response, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SentryError);
    }

    this.name = `Request error`;
    this.message = `API failed with ${response.status} ${response.url}`;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response: Response) {
  if (
    response.status === 204 ||
    response.status === 205 ||
    response.status === 202
  ) {
    return null;
  }

  return response.json();
}

export default async function getNewToken() {
  try {
    const token = await auth0.getTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
      },
    });

    sessionStorage.setItem('t', token);

    return token;
  } catch (e: any) {
    window.location.reload();
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export function checkStatus(response: Response, transactionId: string) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const user_id = sessionStorage.getItem('user_id') || '';

  const response_headers = {};

  if (response.headers) {
    response.headers.forEach((value, key) => {
      response_headers[key] = value;
    });
  }

  const sentryError = new SentryError(response);

  Sentry.captureException(sentryError, {
    tags: {
      curago_transaction_id: transactionId,
      user_id,
      api_url: response.url,
    },
    extra: {
      response_headers,
    },
  });

  const error = new ResponseError(response, '');
  error.response = response;

  throw error;
}

interface IRequestOptions extends RequestInit {
  file?: boolean;
  absoluteUrl?: boolean;
  disableParse?: boolean;
}

export interface IFileResponse {
  blob: Blob;
  fileName: string;
}

export async function request(
  url: string,
  options?: IRequestOptions,
): Promise<{} | { err: ResponseError } | any | IFileResponse> {
  const token = sessionStorage.getItem('t') || '';
  const fetchResponse = options?.absoluteUrl
    ? await fetch(url, options)
    : await fetch(`${API_HOST}${url}`, {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${token}`,
        },
      });

  const transactionId = `qk-web-app-${getHex(5)}`;

  const response = checkStatus(fetchResponse, transactionId);

  if (options?.disableParse) {
    return response;
  }

  if (options?.file) {
    const disposition = response.headers.get('Content-Disposition');

    let fileName;

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

      const matches = fileNameRegex.exec(disposition);

      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    const blob = await response.blob();

    return { blob, fileName } as IFileResponse;
  }

  return parseJSON(response);
}
