import { useEffect } from 'react';

import { useInjectSaga as useSaga } from 'redux-injectors';
import {
  InjectReducerParams,
  InjectSagaParams,
  InjectedReducersType,
  RootStateKeyType,
} from './types/injector-typings';
import { useStore } from 'react-redux';
import { Reducer, Store } from 'redux';

// Is used to handle Warning: Cannot update a component (`Root`) while rendering a different component (`RootAssessor`). To locate the bad setState() call inside `RootAssessor`
// See: https://github.com/react-boilerplate/redux-injectors/issues/19#issuecomment-651878232
type EnhancedStore = Store & {
  injectedReducers: InjectedReducersType;
  createReducer: (injectedReducers: InjectedReducersType) => Reducer;
};

function injectReducerFactory(store: EnhancedStore) {
  return function injectReducer(key: RootStateKeyType, reducer: Reducer) {
    if (
      Reflect.has(store.injectedReducers, key) &&
      store.injectedReducers[key] === reducer
    )
      return;

    store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
    store.replaceReducer(store.createReducer(store.injectedReducers));
  };
}

function getInjectors(store: EnhancedStore) {
  return {
    injectReducer: injectReducerFactory(store),
  };
}

export const useInjectReducer = <Key extends RootStateKeyType>({
  key,
  reducer,
}: InjectReducerParams<Key>) => {
  const store = useStore() as EnhancedStore;

  useEffect(() => {
    getInjectors(store).injectReducer(key, reducer);
  }, [store, key, reducer]);
};

export function useInjectSaga(params: InjectSagaParams) {
  return useSaga(params);
}
