import { rootActions } from 'app/containers/Root/slice/index';
import {
  alertsActions,
  defaultErrorText,
  defaultErrorTitle,
} from 'app/hooks/useAlerts/slice';
import { put } from 'redux-saga/effects';
import getNewToken from 'utils/request';

const EXPIRED_TOKEN_ERROR_MESSAGE = 'invalid or expired jwt';

export interface ErrorHandlerProps {
  page?: boolean;
  crash?: boolean;
  title?: string;
  text?: string;
}

export function* ErrorHandler(error, props?: ErrorHandlerProps) {
  if (error.message === 'invalid_form') {
    return;
  }

  // get new token and reload the page if pervious token has expired
  if (error?.response?.status === 401) {
    const errorBody = yield error.response.json();

    if (errorBody?.message === EXPIRED_TOKEN_ERROR_MESSAGE) {
      yield getNewToken();
      window.location.reload();
      return;
    }

    yield put(rootActions.setError({ error: { type: 'forbidden' } }));
    return;
  }

  if (error?.response?.status === 403) {
    yield put(rootActions.setError({ error: { type: 'forbidden' } }));

    return;
  }

  if (error?.response?.status === 400) {
    //todo ask BE to make type for the validation error
    const response = yield error.response.json();

    yield put(
      alertsActions.addAlert({
        type: 'error',
        title: props?.title || defaultErrorTitle,
        text: response?.message || props?.text || defaultErrorText,
      }),
    );

    return;
  }

  if (error?.response?.status === 404 && props?.page) {
    yield put(rootActions.setError({ error: { type: 'not_found' } }));

    return;
  }

  if (props?.crash) {
    yield put(rootActions.setError({ error: { type: 'crash' } }));

    return;
  }

  yield put(
    alertsActions.addAlert({
      type: 'error',
      title: props?.title || defaultErrorTitle,
      text: props?.text || defaultErrorText,
    }),
  );
}
