import React, { useEffect } from 'react';

import { Global } from '@emotion/react';
import { createTheme, PaletteMode, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Alerts } from 'app/containers/Alerts';
import { AlertsContextProvider } from 'app/containers/Alerts/Context';
import { useAlerts } from 'app/hooks/useAlerts';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { globalStyles } from 'styles/global-styles';
import { getDesignTokens } from 'styles/theme/defaultTheme';
import { Root } from './containers/Root';

import { OTELApi } from '@grafana/faro-web-sdk';

import faro from 'tracing';
import { ENABLE_TRACING } from 'utils/constants';

export type RootParams = {
  questionnaire_id: string;
  category_id: string;
  tab_id: string;
  question_id: string;
};

const mode: PaletteMode = 'light';

export function App() {
  const { i18n } = useTranslation();

  const theme = createTheme(getDesignTokens(mode));

  const alerts = useAlerts();

  //using with faro
  useEffect(() => {
    const OTEL_Instance = faro?.api.getOTEL() as OTELApi;

    if (Boolean(ENABLE_TRACING) && OTEL_Instance) {
      const { trace, context } = OTEL_Instance;

      const tracer = trace.getTracer('default');
      const span = tracer.startSpan('click');

      context.with(trace.setSpan(context.active(), span), () => {
        span.end();
      });
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Helmet
            titleTemplate="Questionnaire"
            defaultTitle="Questionnaire"
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content="Questionnaire" />
          </Helmet>
          <AlertsContextProvider value={alerts}>
            <Root />
            <Alerts />
          </AlertsContextProvider>
          <Global styles={globalStyles} />
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
