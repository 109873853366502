import { ReactComponentElement } from 'react';
import { ReactComponent as analizing } from './svg/analizing.svg';
import { ReactComponent as arrow_down } from './svg/arrow_down.svg';
import { ReactComponent as arrow_left } from './svg/arrow_left.svg';
import { ReactComponent as arrow_right } from './svg/arrow_right.svg';
import { ReactComponent as arrow_up } from './svg/arrow_up.svg';
import { ReactComponent as check } from './svg/check.svg';
import { ReactComponent as check_circle } from './svg/check_circle.svg';
import { ReactComponent as chevron_down } from './svg/chevron_down.svg';
import { ReactComponent as chevron_up } from './svg/chevron_up.svg';
import { ReactComponent as chevron_left } from './svg/chevron_left.svg';
import { ReactComponent as chevron_right } from './svg/chevron_right.svg';
import { ReactComponent as close } from './svg/close.svg';
import { ReactComponent as download } from './svg/download.svg';
import { ReactComponent as dropdown_down } from './svg/dropdown_down.svg';
import { ReactComponent as dropdown_up } from './svg/dropdown_up.svg';
import { ReactComponent as exclamation_mark_filed } from './svg/exclamation_mark_filed.svg';
import { ReactComponent as exclamation_mark_hexagon } from './svg/exclamation_mark_hexagon.svg';
import { ReactComponent as exclamation_mark_reversed } from './svg/exclamation_mark_reversed.svg';
import { ReactComponent as info_circle } from './svg/info_circle.svg';
import { ReactComponent as message } from './svg/message.svg';
import { ReactComponent as more_vert } from './svg/more_vert.svg';
import { ReactComponent as not_found } from './svg/not_found.svg';
import { ReactComponent as no_access } from './svg/no_access.svg';
import { ReactComponent as question } from './svg/question.svg';
import { ReactComponent as remove } from './svg/remove.svg';
import { ReactComponent as review_and_send } from './svg/review_and_send.svg';
import { ReactComponent as share } from './svg/share.svg';
import { ReactComponent as unavailable } from './svg/unavailable.svg';
import { ReactComponent as upload } from './svg/upload.svg';
import { ReactComponent as logout } from './svg/logout.svg';
import { ReactComponent as eye_big_circle } from './svg/eye_big_circle.svg';
import { ReactComponent as check_big_circle } from './svg/check_big_circle.svg';
import { ReactComponent as miratoLogo } from './svg/miratoLogo.svg';
import { ReactComponent as edit } from './svg/edit.svg';
import { ReactComponent as reminder } from './svg/reminder.svg';
import { ReactComponent as export_icon } from './svg/export.svg';
import { ReactComponent as undo } from './svg/undo.svg';
import { ReactComponent as waiting } from './svg/waiting.svg';
import { ReactComponent as dialog } from './svg/dialog.svg';
import { ReactComponent as unanswered } from './svg/unanswered.svg';
import { ReactComponent as comment } from './svg/comment.svg';
import { ReactComponent as new_comment } from './svg/new_comment.svg';
import { ReactComponent as closed_eye } from './svg/closed_eye.svg';
import { ReactComponent as eye } from './svg/eye.svg';
import { ReactComponent as lock } from './svg/lock.svg';
import { ReactComponent as envelop } from './svg/envelop.svg';
import { ReactComponent as deleteIcon } from './svg/delete.svg';
import { ReactComponent as filter } from './svg/filter.svg';
import { ReactComponent as search } from './svg/search.svg';
import { ReactComponent as plus } from './svg/plus.svg';
import { ReactComponent as sent } from './svg/sent.svg';
import { ReactComponent as receive } from './svg/receive.svg';
import { ReactComponent as send } from './svg/send.svg';
import { ReactComponent as history } from './svg/history.svg';
import { ReactComponent as doc_uploaded } from './svg/doc_uploaded.svg';
import { ReactComponent as info } from './svg/info.svg';
import { ReactComponent as settings } from './svg/settings.svg';

export const iconsDictionary = {
  question,
  arrow_left,
  arrow_right,
  arrow_down,
  arrow_up,
  remove,
  close,
  exclamation_mark_filed,
  exclamation_mark_hexagon,
  exclamation_mark_reversed,
  more_vert,
  check_circle,
  check,
  review_and_send,
  chevron_up,
  chevron_down,
  chevron_left,
  chevron_right,
  info_circle,
  upload,
  analizing,
  not_found,
  no_access,
  unavailable,
  dropdown_down,
  dropdown_up,
  share,
  download,
  message,
  logout,
  eye_big_circle,
  check_big_circle,
  miratoLogo,
  edit,
  reminder,
  export_icon,
  undo,
  waiting,
  dialog,
  unanswered,
  comment,
  new_comment,
  closed_eye,
  eye,
  lock,
  envelop,
  deleteIcon,
  filter,
  search,
  plus,
  sent,
  receive,
  send,
  history,
  doc_uploaded,
  info,
  settings,
};

export type IconsMapType = {
  [key in keyof typeof iconsDictionary]: ReactComponentElement<any>;
};
