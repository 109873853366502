import React, { useState } from 'react';

import { AlertCheckbox as AlertCheckboxType } from './types';
import { Box } from 'app/components/Box';
import { CheckBox } from 'app/components/Inputs/CheckBox';
import { Typography } from 'app/components/Typography';

type AlertCheckboxProps = AlertCheckboxType & {};

export function AlertCheckbox(props: AlertCheckboxProps) {
  const { title, initValue, onCheckboxClick, testId } = props;

  const [isChecked, setIsChecked] = useState<boolean>(initValue || false);

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
    onCheckboxClick(!isChecked);
  };

  return (
    <Box display="flex" alignItems="center" marginLeft="-0.7rem">
      <CheckBox
        checked={isChecked}
        onClick={handleCheckboxClick}
        data-test={testId}
      />
      <Typography>{title}</Typography>
    </Box>
  );
}
