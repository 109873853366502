import React, { FC, ReactNode } from 'react';

import { Typography } from 'app/components/Typography';
import { Box } from 'app/components/Box';
import { Icon } from 'app/components/Icon';
import { IconsMapType } from 'app/components/Icon/Icons';

export type MessageType = 'critical' | 'warning' | 'info';

export interface MessageProps {
  type: MessageType;
  text: ReactNode;
}

const setMessageIcon = (type: MessageType): keyof IconsMapType => {
  switch (type) {
    case 'critical':
      return 'exclamation_mark_filed';
    case 'warning':
      return 'exclamation_mark_filed';
    case 'info':
    default:
      return 'info_circle';
  }
};

const setMessageIconColor = (type: MessageType): string => {
  switch (type) {
    case 'critical':
      return '#F08D31';
    case 'warning':
      return '#F08D31';
    case 'info':
    default:
      return '#558CFF';
  }
};

export const Message: FC<MessageProps> = ({ text, type }) => {
  const messageIcon = setMessageIcon(type);
  const messageIconColor = setMessageIconColor(type);

  return (
    <Box display="flex" gap=".5rem" alignItems="start" m="1rem 0 0 0">
      <Box marginTop="-0.2rem" marginRight="-0.2rem">
        <Icon name={messageIcon} color={messageIconColor} />
      </Box>
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};
