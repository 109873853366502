import React from 'react';

import { ErrorPage } from 'app/components/Errors/ErrorPage';

export const CRASH_TITLE = 'Oops, something went wrong';

export const Crash = () => {
  return (
    <ErrorPage
      title={CRASH_TITLE}
      subtitle="Looks like our questionnaire killer has struck again - this time, taking out the very page you were looking for!"
      iconName="not_found"
    />
  );
};
