import React, { FC } from 'react';
import MUIDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material/DialogTitle';

export interface DialogTitleProps extends MuiDialogTitleProps {}

export const DialogTitle: FC<DialogTitleProps> = props => {
  return <MUIDialogTitle {...props} />;
};
