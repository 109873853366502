import { createContext, useContext, Provider } from 'react';

export type MakeContextType = <T extends {}>(
  name: string,
) => readonly [() => T, Provider<T | undefined>];

export const makeContext: MakeContextType = <T>(name) => {
  const Context = createContext<T | undefined>(undefined);

  const useMakeContext = () => {
    const context = useContext(Context);
    if (context === undefined) {
      throw new Error(
        `use${name}Context must be used within a Context of ${name}`,
      );
    }
    return context;
  };

  return [useMakeContext, Context.Provider];
};
