import React from 'react';

import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type ICheckboxProps = CheckboxProps & {};

export const StyledCheckBox = styled(MuiCheckbox, {
  shouldForwardProp: prop => prop !== 'error',
})<ICheckboxProps>`
  ${({ size }) => size === 'small' && 'padding: 4px'}
`;

export const CheckBox = ({ size, ...rest }: ICheckboxProps) => (
  <StyledCheckBox size={size} {...rest} />
);
