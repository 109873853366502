import { PaletteMode } from '@mui/material';

export const getPalette = (_mode: PaletteMode) => {
  return {
    primary: {
      main: '#5E45EB',
      light: '#DFDCF4',
      dark: '#4A55A6',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#7B7B7B',
      light: 'rgba(61, 79, 228, 0.2)',
      dark: 'rgba(234,236,245, 0.8)',
      contrastText: '#1A1719',
    },
    divider: '#ddd',
    background: {
      default: '#fff',
      paper: '#fff',
      dark: '#ddd',
    },
    text: {
      primary: '#1E1E1E',
      secondary: '#888F9A',
    },
  };
};
