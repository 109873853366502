import bankLogo from './mars_logo.svg';
import miratoLogo from './logoMirato2.png';
import qkLogo from './qkLogo.png';

declare global {
  interface Window {
    NODE_ENV?: string;
    API_HOST?: string;
    AUTH0_CLIENT_ID?: string;
    AUTH0_DOMAIN?: string;
    AUTH0_AUDIENCE?: string;
    SENTRY_DSN?: string;
    SENTRY_ENV?: string;
    FARO_API_KEY?: string;
    FARO_COLLECTOR_URL?: string;
    ENABLE_DEV_TOOLS?: string;
    ENABLE_TRACING?: string;
  }

  interface env {
    NODE_ENV?: string;
    REACT_APP_USE_MOCK?: string;
    REACT_APP_API_HOST?: string;
    REACT_APP_AUTH0_CLIENT_ID?: string;
    REACT_APP_AUTH0_DOMAIN?: string;
    REACT_APP_AUTH0_AUDIENCE?: string;
    REACT_APP_SENTRY_DSN?: string;
    REACT_APP_SENTRY_ENV?: string;
    REACT_APP_FARO_API_KEY?: string;
    REACT_APP_FARO_COLLECTOR_URL?: string;
    REACT_APP_ENABLE_DEV_TOOLS?: string;
    REACT_APP_ENABLE_TRACING?: string;
  }
}

export const NODE_ENV = window.NODE_ENV || process.env.NODE_ENV;

export const API_HOST =
  process.env.REACT_APP_API_HOST || window.API_HOST || '/api';

export const USE_MOCK = process.env.REACT_APP_USE_MOCK === 'true' || false;

export const BANK_LOGO_URL = bankLogo;
export const MIRATO_LOGO_URL = miratoLogo;
export const QK_LOGO_URL = qkLogo;

export const DATE_FORMAT = 'dd-MMM-yyyy';
export const DATE_FORMAT_WITH_HOURS = 'dd-MMM-yyyy | hh:mm';

export const SUPPORT_LINK = `mailto:support@mirato.com`;

// ---- mock
export const VENDOR_ID = process.env.REACT_APP_VENDOR_ID || '64889032';
// ---- AUTH0
export const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID || window.AUTH0_CLIENT_ID || '';
export const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN || window.AUTH0_DOMAIN || '';
export const AUTH0_AUDIENCE =
  process.env.REACT_APP_AUTH0_AUDIENCE || window.AUTH0_AUDIENCE;

// ---- Sentry
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN || window.SENTRY_DSN || '';
export const SENTRY_ENV =
  process.env.REACT_APP_SENTRY_ENV || window.SENTRY_ENV || '';

// ---- Faro
export const FARO_API_KEY =
  process.env.REACT_APP_FARO_API_KEY || window.FARO_API_KEY || '';
export const FARO_COLLECTOR_URL =
  process.env.REACT_APP_FARO_COLLECTOR_URL || window.FARO_COLLECTOR_URL || '';
export const ENABLE_TRACING =
  process.env.REACT_APP_ENABLE_TRACING === 'true' ||
  window.ENABLE_TRACING === 'true' ||
  false;

export const ENABLE_DEV_TOOLS =
  process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true' ||
  window.ENABLE_DEV_TOOLS === 'true' ||
  false;
