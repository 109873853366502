export const setSessionUrl = () => {
  const { pathname, search } = window.location;

  sessionStorage.setItem('t_pathname', pathname);
  sessionStorage.setItem('t_search', search);
};

export const getSessionUrl = (): { t_pathname: string; t_search: string } => ({
  t_pathname: sessionStorage.getItem('t_pathname') || '',
  t_search: sessionStorage.getItem('t_search') || '',
});

export const clearSessionUrl = () => {
  sessionStorage.removeItem('t_pathname');
  sessionStorage.removeItem('t_search');
};
