import React from 'react';
import MUITypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { MuiProps } from 'types/common';

export type TypographyProps = MuiTypographyProps & MuiProps;

export const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  (props, ref) => {
    return <MUITypography {...props} ref={ref} />;
  },
);
