/**
 *
 * AlertTitle
 *
 */

import React from 'react';
import { Typography } from 'app/components/Typography';
import { Icon } from 'app/components/Icon';
import { StyledCloseButton, StyledTitle } from './styled';
import { useTheme } from '@mui/material';
import { IconsMapType } from 'app/components/Icon/Icons';
import { Box } from 'app/components/Box';

interface IAlertTitleProps {
  children?: React.ReactNode;
  onClose?: () => void;
  hasBorder?: boolean;

  icon?: keyof IconsMapType;
}

export const AlertTitle = ({
  children,
  onClose,
  hasBorder,
  icon,
}: IAlertTitleProps) => {
  const { palette } = useTheme();

  return (
    <StyledTitle
      borderBottom={hasBorder ? `1px solid ${palette.divider}` : 'unset'}
    >
      <Box display="flex" alignItems="center" gap="1rem">
        {icon && <Icon name={icon} />}
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          fontWeight="600"
        >
          {children}
        </Typography>
      </Box>
      {onClose && (
        <StyledCloseButton
          aria-label="close"
          onClick={onClose}
          data-test="action_close_popup"
        >
          <Icon name="close" color="#AFB4BD" />
        </StyledCloseButton>
      )}
    </StyledTitle>
  );
};
