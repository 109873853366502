import { PaletteMode, ThemeOptions } from '@mui/material';
import { getLayoutStyles } from './getLayoutStyles';
import { getPalette } from './getPalette';

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: getPalette(mode),

  layout: getLayoutStyles(mode),

  typography: {
    fontFamily: 'Poppins, sans-serif',
  },

  shape: {
    borderRadius: 2,
  },

  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#FF0000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: '#4B5057',
        },
      },
    },
  },
});
