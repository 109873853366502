import React from 'react';

import { useSelector } from 'react-redux';
import { selectRoot } from 'app/containers/Root/slice/selectors';
import { Forbidden } from 'app/containers/Root/ErrorContainer/Forbidden';
import { NotFound } from 'app/containers/Root/ErrorContainer/NotFound';
import { Crash } from 'app/containers/Root/ErrorContainer/Crash';

export const ErrorContainer = () => {
  const { error } = useSelector(selectRoot);

  if (error?.type === 'forbidden') {
    return <Forbidden />;
  }

  if (error?.type === 'not_found') {
    return <NotFound />;
  }

  return <Crash />;
};
