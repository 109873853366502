import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { AddAlertAction, AlertsState, RemoveAlertAction } from './types';
import { IAlertDefault } from 'app/containers/Alerts/types';

export const initialState: AlertsState = {
  alerts: [],
};

export const defaultErrorTitle = 'Error!';

export const defaultErrorText = 'Something went wrong';

const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert(state, action: PayloadAction<AddAlertAction>) {
      state.alerts.push({
        ...action.payload,
        id: action.payload.id || Math.random().toString(),
        // workaround to achieve props compatibility. See similar issue https://github.com/mui/material-ui/issues/15697#issuecomment-493419773
        ...(action.payload.type === 'default'
          ? {
              inputProps: (action.payload as IAlertDefault).inputProps as any,
            }
          : {}),
      });
    },
    addDefaultErrorAlert(state) {
      state.alerts.push({
        id: Math.random().toString(),
        type: 'error',
        title: defaultErrorTitle,
        text: defaultErrorText,
      });
    },
    removeAlert(state, action: PayloadAction<RemoveAlertAction>) {
      state.alerts = state.alerts.filter(({ id }) => id !== action.payload.id);
    },
    removeAlerts(state) {
      state.alerts = [];
    },
  },
});

export const { actions: alertsActions } = slice;

export const useAlertsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
