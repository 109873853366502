import React, { useEffect } from 'react';

import { useRootSlice } from 'app/containers/Root/slice';
import { selectRoot } from 'app/containers/Root/slice/selectors';
import { RootAssessor } from 'app/containers/RootAssessor/Loadable';
import { RootVendor } from 'app/containers/RootVendor/Loadable';
import { useAbstractDataSlice } from 'app/hooks/useGetAbstractData/slice';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorContainer } from 'app/containers/Root/ErrorContainer';
import { NotFound } from 'app/containers/Root/ErrorContainer/NotFound';
import { LoadingPage } from 'app/components/LoadingPage';
import { RootAdmin } from '../RootAdmin/Loadable';

export function Root() {
  useAbstractDataSlice();

  const dispatch = useDispatch();

  const { actions } = useRootSlice();

  const { init } = actions;

  const { currentUser, error } = useSelector(selectRoot);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(init({ navigate }));
    // eslint-disable-next-line
  }, []);

  if (error) {
    return <ErrorContainer />;
  }

  if (!currentUser) {
    return <LoadingPage isLoading fullPage />;
  }

  return (
    <Routes>
      <Route path="/assessor/*" element={<RootAssessor />} />
      <Route path="/vendor/*" element={<RootVendor />} />
      <Route path="/admin/*" element={<RootAdmin />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
