import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { abstractDataNames } from 'app/hooks/useGetAbstractData/abstractDataNames';
import {
  AbstractDataState,
  IGetAbstractData,
  IGetAbstractDataAbort,
  IGetAbstractDataError,
  IGetAbstractDataOk,
  IResetAbstractData,
} from 'app/hooks/useGetAbstractData/slice/types';
import { AbstractDataName } from 'app/hooks/useGetAbstractData/types';

export const initialState: AbstractDataState = abstractDataNames.reduce(
  (acc, key) => ({ ...acc, [key]: { loading: false } }),
  {},
) as AbstractDataState;

const slice = createSlice({
  name: 'abstractData',
  initialState,
  reducers: {
    resetAbstractData<T extends AbstractDataName>(
      state,
      action: PayloadAction<IResetAbstractData<T>>,
    ) {
      const { name } = action.payload;

      state[name].loading = false;
      state[name].error = false;
      state[name].data = undefined;
    },
    getAbstractData<T extends AbstractDataName>(
      state,
      action: PayloadAction<IGetAbstractData<T>>,
    ) {
      const { name } = action.payload;

      state[name].loading = true;
      state[name].error = false;
    },
    getAbstractDataAbort<T extends AbstractDataName>(
      state,
      action: PayloadAction<IGetAbstractDataAbort<T>>,
    ) {
      const { name } = action.payload;

      state[name].loading = false;
    },
    getAbstractDataOk<T extends AbstractDataName>(
      state,
      action: PayloadAction<IGetAbstractDataOk<T>>,
    ) {
      const { name, data, currentParams } = action.payload;

      state[name].loading = false;
      state[name].data = data;
      state[name].currentParams = currentParams;
    },
    getAbstractDataError<T extends AbstractDataName>(
      state,
      action: PayloadAction<IGetAbstractDataError<T>>,
    ) {
      const { name, error } = action.payload;

      state[name].loading = false;
      state[name].error = error;
    },
  },
});

export const { actions: abstractDataActions } = slice;

export const useAbstractDataSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
