import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { rootSaga } from './saga';
import {
  IErrorAction,
  IGetCurrentUserOk,
  IInitAction,
  ILogoutAction,
  ISendFeedback,
  RootState,
} from './types';

export const initialState: RootState = {
  loading: true,
};

const slice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    init(state, _action: PayloadAction<IInitAction>) {},
    setError(state, action: PayloadAction<IErrorAction>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    clearError(state) {
      state.error = undefined;
    },

    authentication(state, _action: PayloadAction<IInitAction>) {},
    authenticationOk(_state) {},

    logout(state, _action: PayloadAction<ILogoutAction>) {
      state.loading = true;
    },

    getCurrentUser(_state) {},
    getCurrentUserOk(state, action: PayloadAction<IGetCurrentUserOk>) {
      state.currentUser = action.payload;
    },

    sendFeedback(_state, _action: PayloadAction<ISendFeedback>) {},
  },
});

export const { actions: rootActions } = slice;

export const useRootSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: rootSaga });
  return { actions: slice.actions };
};
