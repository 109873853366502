const errorsFilterSet = new Set(['offsetParent is not set -- cannot scroll']);

export const errorsFilter = () => {
  const originalError = console.error;

  console.error = (e: string) => {
    if (!errorsFilterSet.has(e)) {
      originalError(e);
    }
  };
};
