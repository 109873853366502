import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorHandler } from 'app/containers/Root/slice/generators/errors';
import {
  selectCurrentUser,
  selectIsAssessor,
} from 'app/containers/Root/slice/selectors';
import {
  IInitAction,
  ILogoutAction,
  ISendFeedback,
} from 'app/containers/Root/slice/types';
import {
  clearSessionUrl,
  getSessionUrl,
  setSessionUrl,
} from 'app/containers/Root/slice/utils';
import {
  call,
  debounce,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { auth0 } from 'utils/auth0';
import { AUTH0_AUDIENCE, NODE_ENV, USE_MOCK } from 'utils/constants';
import { rootActions as actions } from '.';
import { IUser } from 'types/user';
import { getCurrentUser, IResponseCurrentUser } from 'api/getCurrentUser';

import * as Sentry from '@sentry/react';

interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

declare global {
  interface Window {
    __insp: string[][];
    heap: Heap;
  }
}

function* initSaga(action: PayloadAction<IInitAction>) {
  const { payload } = action;

  yield put(actions.authentication(payload));

  yield take(actions.authenticationOk.type);

  yield put(actions.getCurrentUser());

  yield take(actions.getCurrentUserOk.type);

  yield setHeapIdentity();

  yield checkPermittedRoute(action);

  yield setupInspectlet();
}

function* setHeapIdentity() {
  const { email }: IUser = yield select(selectCurrentUser);

  if (window.heap && email) {
    window.heap.identify(email);
  }
}

function* setupInspectlet() {
  if (!window.__insp) {
    return;
  }

  const isAssessor = yield select(selectIsAssessor);
  const qnr_id = window.location.pathname.split('/')[2];

  window.__insp.push(['Peraons', isAssessor ? 'assessor' : 'vendor']);

  if (!isAssessor && qnr_id) {
    window.__insp.push(['QNR ID', qnr_id]);
  }
}

export function* checkSession() {
  const { search, pathname, origin } = window.location;

  const isVendor = pathname.includes('vendor');

  const shouldParseResult =
    search.includes('code=') || search.includes('state=');

  try {
    if (shouldParseResult) {
      yield auth0.handleRedirectCallback();
    }

    return yield auth0.getTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
      },
    });
  } catch (e: any) {
    if (e.error !== 'login_required') {
      throw e;
    }

    yield auth0.loginWithRedirect({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
        redirect_uri: origin,
        login_hint: isVendor ? 'vendor' : 'assessor',
      },
    });
  }
}

function* authenticationSaga() {
  if (window.location.pathname !== '/') {
    setSessionUrl();

    const qnr_id = window.location.pathname.split('/')[2];

    const isVendor = window.location.pathname.split('/')[1] === 'vendor';

    if (!isVendor) {
      localStorage.removeItem('vendor_qnr_id');
    }

    if (isVendor && qnr_id) {
      localStorage.setItem('vendor_qnr_id', qnr_id);
    }
  }

  if (USE_MOCK) {
    yield put(actions.authenticationOk());
    return null;
  }

  const token = yield checkSession();

  if (!token) {
    return null;
  }

  sessionStorage.setItem('t', token);

  yield put(actions.authenticationOk());
}

function* checkPermittedRoute({ payload }: PayloadAction<IInitAction>) {
  const { navigate } = payload;

  const { user_permissions }: IUser = yield select(selectCurrentUser);

  const saved_qnr_id = localStorage.getItem('vendor_qnr_id');

  const { t_pathname, t_search } = getSessionUrl();

  if (!user_permissions) {
    yield put(actions.setError({ error: { type: 'forbidden' } }));
    return;
  }

  if (!saved_qnr_id && !t_pathname) {
    navigate('/assessor');
    return;
  }

  const qnr_id_path = t_pathname.split('/')[2];

  if (!!saved_qnr_id && !qnr_id_path) {
    navigate(`/vendor/${saved_qnr_id}`);
    return;
  }

  navigate(t_pathname + t_search);
  clearSessionUrl();
}

function* getCurrentUserSaga() {
  try {
    const user: IResponseCurrentUser = yield call(getCurrentUser, {});

    sessionStorage.setItem('user_id', user.id);

    yield put(actions.getCurrentUserOk(user));
  } catch (e) {
    yield ErrorHandler(e, { crash: true });
  }
}

function* logoutSaga(_: ILogoutAction) {
  try {
    if (USE_MOCK) {
      return null;
    }

    const saved_qnr_id = localStorage.getItem('vendor_qnr_id');

    const { origin } = window.location;

    sessionStorage.clear();
    localStorage.clear();

    const preventBack = () => {
      window.history.forward();
    };
    setTimeout(preventBack, 0);

    window.onunload = () => {
      return null;
    };

    if (!!saved_qnr_id) {
      // in case of vendor qnr_id should be stored in local storage after the logout
      localStorage.setItem('vendor_qnr_id', saved_qnr_id);

      return yield auth0.logout({
        logoutParams: {
          returnTo: `${origin}/vendor`,
        },
      });
    }

    yield auth0.logout({
      logoutParams: {
        returnTo: origin,
      },
    });
  } catch (e) {
    yield ErrorHandler(e, { crash: true });
  }
}

export function sendFeedbackSaga({ payload }: PayloadAction<ISendFeedback>) {
  const eventId = Sentry.captureMessage(payload.transactionId);
  Sentry.showReportDialog({
    eventId,
    title: 'Your Feedback',
    subtitle: 'It looks like we’re having some internal issues.',
    subtitle2: `\nYou can provide our team additional information about the issue you have encountered. \n \n [Request ID: ${payload.transactionId}]`,
    labelName: 'Your Name',
    labelEmail: 'Your Email',
    labelComments: 'Your Feedback',
    labelSubmit: 'Send',
    labelClose: 'Cancel',
  });
}

export function* rootSaga() {
  if (NODE_ENV === 'development') {
    yield debounce(200, actions.init.type, initSaga);
  } else {
    yield takeLatest(actions.init.type, initSaga);
  }
  yield takeLatest(actions.authentication.type, authenticationSaga);
  yield takeLatest(actions.getCurrentUser.type, getCurrentUserSaga);
  yield takeLatest(actions.logout.type, logoutSaga);

  yield takeLatest(actions.sendFeedback.type, sendFeedbackSaga);
}
