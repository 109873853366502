import { trace, context } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { initializeFaro, Faro } from '@grafana/faro-web-sdk';
import {
  FaroSessionSpanProcessor,
  FaroTraceExporter,
} from '@grafana/faro-web-tracing';
import {
  FARO_API_KEY,
  FARO_COLLECTOR_URL,
  ENABLE_TRACING,
} from './utils/constants';

const VERSION = process.env.npm_package_version;
const NAME = 'mqk-web-app';
const COLLECTOR_URL = FARO_COLLECTOR_URL;

let faro: Faro | undefined = undefined;

if (
  Boolean(ENABLE_TRACING) &&
  Boolean(COLLECTOR_URL) &&
  Boolean(FARO_API_KEY)
) {
  // initialize faro
  faro = initializeFaro({
    url: COLLECTOR_URL,
    apiKey: FARO_API_KEY,
    app: {
      name: NAME,
      version: VERSION,
    },
  });

  // set up otel
  const resource = Resource.default().merge(
    new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: NAME,
      [SemanticResourceAttributes.SERVICE_VERSION]: VERSION,
    }),
  );

  const provider = new WebTracerProvider({ resource });

  provider.addSpanProcessor(
    new FaroSessionSpanProcessor(
      new BatchSpanProcessor(new FaroTraceExporter({ ...faro })),
      faro.metas,
    ),
  );

  provider.register({
    propagator: new W3CTraceContextPropagator(),
    contextManager: new ZoneContextManager(),
  });

  const ignoreUrls = [COLLECTOR_URL];

  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new FetchInstrumentation({ ignoreUrls }),
      new XMLHttpRequestInstrumentation({ ignoreUrls }),
      new UserInteractionInstrumentation(),
    ],
  });

  // register OTel with Faro
  faro.api.initOTEL(trace, context);
}
export default faro;
