import React, { FC, useEffect, useState } from 'react';

import { Box } from 'app/components/Box';
import { Button } from 'app/components/Buttons/Button';
import { Icon } from 'app/components/Icon';
import { IconsMapType } from 'app/components/Icon/Icons';
import { Link } from 'app/components/Link';
import { Typography } from 'app/components/Typography';
import { styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useRootSlice } from 'app/containers/Root/slice';
import { SUPPORT_LINK } from 'utils/constants';
import {
  IResponseRelationshipManager,
  getVendorRelationshipManager,
} from 'api/getRelationshipManager';
import { useErrorNavigate } from 'app/containers/Root/ErrorContainer/useErrorNavigate';
import { CRASH_TITLE } from 'app/containers/Root/ErrorContainer/Crash';

export interface ErrorPageProps {
  title: string;
  subtitle: string;
  iconName: keyof IconsMapType;
}

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[700]};
  letter-spacing: 0em;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.grey[700]};
  padding-top: 0.5rem;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
`;

export const ErrorPage: FC<ErrorPageProps> = ({
  title,
  subtitle,
  iconName,
}) => {
  const [relationshipData, setRelationshipData] =
    useState<IResponseRelationshipManager>();

  const saved_qnr_id = localStorage.getItem('vendor_qnr_id');

  useEffect(() => {
    if (!!saved_qnr_id) {
      getVendorRelationshipManager({
        questionnaire_id: saved_qnr_id,
        vendor_id: '',
      }).then(res => setRelationshipData(res));
    }
  }, [saved_qnr_id]);

  const { handleNavigate } = useErrorNavigate({
    isAssessor: !saved_qnr_id,
    isCrash: title === CRASH_TITLE,
    questionnaire_id: saved_qnr_id,
  });

  const dispatch = useDispatch();

  const { actions } = useRootSlice();

  const handleLogout = () => {
    dispatch(actions.logout({}));
  };

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-around"
      width="100vw"
      height="100vh"
      padding="14%"
      gap="2rem"
    >
      <Icon color="#E0D7FF" name={iconName} width="8rem" height="8rem" />
      <Typography variant="h5" sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
      <StyledTypography variant="body2" maxWidth="30rem" align="center">
        {subtitle}
      </StyledTypography>
      <StyledLink
        variant="body1"
        target="_blank"
        href={
          !saved_qnr_id
            ? `mailto:${relationshipData?.relationship_manager_email}`
            : SUPPORT_LINK
        }
      >
        Contact us
      </StyledLink>
      <Button variant="contained" size="large" onClick={handleNavigate}>
        Home Page
      </Button>
      <Box display="flex" flexDirection="column" paddingTop="5rem">
        <StyledTypography variant="body2" maxWidth="30rem" align="center">
          In case the issue persists, try to re-login
        </StyledTypography>
        <StyledLink onClick={handleLogout}>
          <Icon name="logout" width="1rem" height="1rem" m="0 0.5rem 0 0" />
          Log out
        </StyledLink>
      </Box>
    </Box>
  );
};
