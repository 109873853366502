import React, { FC } from 'react';

import MUIButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { MuiProps } from 'types/common';
import { Progress } from 'app/components/Progress';

export type ButtonProps = MuiButtonProps &
  MuiProps & {
    loading?: boolean;
    underline?: boolean;
  };

export const StyledButton = styled(MUIButton, {
  shouldForwardProp: prop => prop !== 'loading' && prop !== 'underline',
})<ButtonProps>`
  border-radius: 5px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.palette.primary.main};

  text-transform: none;
  font-weight: 700;
  white-space: nowrap;

  &.MuiButton-sizeSmall {
    min-width: 2.8rem;
    min-height: 2rem;
    padding: 0 1rem;
  }

  &.MuiButton-sizeMedium {
    min-width: 4rem;
    min-height: 2.4rem;
    padding: 0 1rem;
  }

  &.MuiButton-sizeLarge {
    min-width: 6rem;
    min-height: 2.8rem;
  }

  ${({ underline }) => underline && 'text-decoration: underline;'}

  &:hover {
    ${({ underline }) => underline && 'text-decoration: underline;'}
  }
`;

export const StyledLoadingOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button: FC<ButtonProps> = props => {
  const { loading, children } = props;
  if (loading) {
    return (
      <StyledButton {...props} disabled>
        {children}
        <StyledLoadingOverlay>
          <Progress type="circular" />
        </StyledLoadingOverlay>
      </StyledButton>
    );
  }
  return <StyledButton {...props} />;
};
