/**
 *
 * Asynchronously loads the component for Test
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RootVendor = lazyLoad(
  () => import('./index'),
  module => module.RootVendor,
);
