import { IVendorPayload, RequestApi } from 'api/types';
import { IQuestionnaireParams } from 'types/common';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { getRelationshipManagerMock } from './mock';

export interface IPayloadVendorRelationshipManager
  extends Pick<IQuestionnaireParams, 'questionnaire_id'>,
    IVendorPayload {}

export interface IResponseRelationshipManager {
  relationship_manager_email: string;
  relationship_manager_name: string;
}

export const getVendorRelationshipManager: RequestApi<
  IPayloadVendorRelationshipManager,
  IResponseRelationshipManager
> = async ({ questionnaire_id, vendor_id }) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getRelationshipManagerMock);
      }, 300);
    });
  }

  return request(
    `/tp/${vendor_id}/questionnaires/${questionnaire_id}/relationship-manager`,
  );
};
