/**
 *
 * Asynchronously loads the component for Test
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RootAssessor = lazyLoad(
  () => import('./index'),
  module => module.RootAssessor,
);
