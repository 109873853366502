import React from 'react';

import { ErrorPage } from 'app/components/Errors/ErrorPage';

export const NotFound = () => {
  return (
    <ErrorPage
      title="Oops, page not found"
      subtitle="Looks like our questionnaire killer has struck again - this time, taking out the very page you were looking for!"
      iconName="not_found"
    />
  );
};
