/**
 *
 * AlertError
 *
 */
import React from 'react';

import { useTheme } from '@mui/material';
import { Box } from 'app/components/Box';
import { Button } from 'app/components/Buttons/Button';
import { Dialog } from 'app/components/Dialog';
import { DialogActions } from 'app/components/Dialog/DialogActions';
import { DialogContent } from 'app/components/Dialog/DialogContent';
import { Icon } from 'app/components/Icon';
import { Typography } from 'app/components/Typography';
import { AlertTitle } from 'app/containers/Alerts/AlertTitle';
import { useAlerts } from 'app/containers/Alerts/Context';
import { IAlertError } from './types';

interface IAlertErrorProps extends IAlertError {}

export function AlertError(props: IAlertErrorProps) {
  const { id, text, title } = props;

  const { removeAlert } = useAlerts();

  const handleClose = () => {
    removeAlert(id);
  };

  const theme = useTheme();

  const paperProps = { style: { borderTop: theme.layout.alert.errorBorder } };

  return (
    <Dialog
      id={id}
      open={!!id}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={paperProps}
    >
      <AlertTitle onClose={handleClose}>{title}</AlertTitle>
      <DialogContent>
        {!!text && (
          <Box display="flex" alignItems="center" gap=".5rem">
            <Icon name="exclamation_mark_filed" color="#F08D31" />
            <Typography variant="body2">{text}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
