import React, { FC } from 'react';
import MUIDialogActions, {
  DialogActionsProps,
} from '@mui/material/DialogActions';
import { styled } from '@mui/material';

const StyledDialogActions = styled(MUIDialogActions)`
  &.MuiDialogActions-root {
    padding-right: 1rem;
  }
`;

export const DialogActions: FC<DialogActionsProps> = props => {
  return <StyledDialogActions {...props} />;
};
