/**
 *
 * Alert
 *
 */

import React from 'react';

import { IAlert } from './types';
import { AlertError } from './AlertError';
import { AlertDefault } from './AlertDefault';

interface IAlertProps {
  alert: IAlert;
}

export function Alert({ alert }: IAlertProps) {
  switch (alert.type) {
    case 'error':
      return <AlertError {...alert} />;
    case 'default':
      return <AlertDefault {...alert} />;
    default:
      return null;
  }
}
