import { INewAlert, IUseAlertsController } from 'app/containers/Alerts/types';
import { useAlertsSlice } from 'app/hooks/useAlerts/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAlerts } from 'app/hooks/useAlerts/slice/selectors';

interface IUseAlerts extends IUseAlertsController {}

export const useAlerts = (): IUseAlerts => {
  const dispatch = useDispatch();

  const { actions } = useAlertsSlice();

  const { alerts } = useSelector(selectAlerts);

  const addAlert = (alert: INewAlert) => {
    dispatch(
      actions.addAlert({ ...alert, id: alert.id || Math.random().toString() }),
    );
  };

  const addDefaultErrorAlert = () => {
    dispatch(actions.addDefaultErrorAlert());
  };

  const removeAlert = (id: string) => {
    dispatch(actions.removeAlert({ id }));
  };

  const removeAlerts = () => {
    dispatch(actions.removeAlerts());
  };

  return { alerts, addAlert, removeAlert, removeAlerts, addDefaultErrorAlert };
};
