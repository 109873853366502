import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { getCurrentUserMock } from 'api/getCurrentUser/mock';
import { request } from 'utils/request';
import { IUser } from 'types/user';

export type IResponseCurrentUser = IUser;
export const getCurrentUser: RequestApi<
  {},
  IResponseCurrentUser
> = async () => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getCurrentUserMock);
      }, 300);
    });
  }

  return request(`/current-user`);
};
