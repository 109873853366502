/**
 *
 * Alerts
 *
 */

import React from 'react';
import { useAlerts } from 'app/containers/Alerts/Context';
import { Alert } from './Alert';

export function Alerts() {
  const { alerts } = useAlerts();

  return <>{alerts?.map(alert => <Alert key={alert.id} alert={alert} />)}</>;
}
