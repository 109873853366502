import { styled } from '@mui/material/styles';
import { IconButton } from 'app/components/Buttons/IconButton';
import {
  DialogTitle,
  DialogTitleProps,
} from 'app/components/Dialog/DialogTitle';

export const StyledTitle = styled(DialogTitle)<DialogTitleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 3.5rem 0.5rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  background: ${({ theme }) => theme.palette.grey[100]};
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;
