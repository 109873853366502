export class ResponseError extends Error {
  public response: Response;
  public transactionId: string;

  constructor(response: Response, transactionId: string) {
    super(response.statusText);
    this.response = response;
    this.transactionId = transactionId;
  }
}

export type ErrorType = 'crash' | 'block_crash' | 'not_found' | 'forbidden';

export interface IError {
  type: ErrorType;
  message?: string;
}

export type ValidationError<T> = {
  field: T;
  message: string;
};
