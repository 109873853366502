import React, { FC } from 'react';
import MUIDialogContent, {
  DialogContentProps as MUIDialogContentProps,
} from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

export interface DialogContentProps extends MUIDialogContentProps {
  gray?: boolean;
}

export const StyledDialogContent = styled(MUIDialogContent, {
  shouldForwardProp: prop => prop !== 'gray',
})<DialogContentProps>`
  ${({ gray, theme }) => gray && `background: ${theme.palette.grey.A100}`}

  &.MuiDialogContent-root {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }
`;

export const DialogContent: FC<DialogContentProps> = props => {
  return <StyledDialogContent {...props} />;
};
