export const getHex = size =>
  [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');

export const typeOf = (value: any): string => {
  if (value === null) {
    return 'null';
  }
  return typeof value;
};
