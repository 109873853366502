/**
 *
 * TextField
 *
 */
import React from 'react';

import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import error_icon from './error_icon.svg';
import warning_icon from './warning_icon.svg';
import { Progress } from 'app/components/Progress';

export type ITextFieldProps = TextFieldProps & {
  readOnly?: boolean;
  width?: string;
  loading?: boolean;
  test_id?: string;
};

export const StyledTextField = styled(MuiTextField)<ITextFieldProps>`
  fieldset {
    top: 0;

    legend {
      display: none;
    }
  }

  input:-webkit-autofill {
    -webkit-background-clip: text !important;
  }

  .MuiFormLabel-root {
    position: relative;
    transform: none;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0.2rem 0;
    white-space: normal;

    &.MuiFormLabel-colorPrimary {
      color: ${({ theme }) => theme.palette.text.primary};

      &.Mui-disabled {
        color: ${({ theme, readOnly }) =>
          readOnly && theme.palette.text.secondary};
      }

      &.Mui-focused {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }

    &.MuiFormLabel-colorSecondary {
      color: ${({ theme }) => theme.palette.text.secondary};

      &.Mui-disabled {
        color: ${({ theme, readOnly }) =>
          readOnly && theme.palette.text.secondary};
      }

      &.Mui-focused {
        color: ${({ theme }) => theme.palette.text.secondary};
      }
    }
  }

  .MuiOutlinedInput-root {
    background: ${({ theme, readOnly }) =>
      readOnly ? 'transparent' : theme.palette.background.default};
    padding: 5px 16px 5px 10px;
    border-radius: 6px;

    .MuiOutlinedInput-input {
      height: auto;
      line-height: 1.3;

      padding: 7px 8px;

      &::placeholder {
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.palette.text.secondary};
        opacity: 1;
      }

      &.Mui-disabled {
        -webkit-text-fill-color: ${({ theme, readOnly }) =>
          readOnly && theme.palette.text.primary};
      }
    }

    .MuiSelect-select {
      height: 1.2876em;
      min-height: 1.2876em;

      padding-top: 6px;
      padding-bottom: 8px;
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.divider};
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.divider};
    border-width: 2px;
  }

  .MuiFormHelperText-root {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 0.5rem 1rem 0.5rem 2rem;
    margin: 0.5rem 0;
    background-color: ${({ theme }) => theme.palette.grey.A200};
    background-image: url('${({ error }) =>
      error ? error_icon : warning_icon}');
    background-size: 0.8rem 0.8rem;
    background-position: 0.5rem 0.7rem;
  }
`;

interface LoadingBoxProps {
  loading?: boolean;
  margin?: boolean;
}

export const LoadingBox = styled('div', {
  shouldForwardProp: prop => prop !== 'loading' && prop !== 'margin',
})<LoadingBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;

  ${({ margin }) => margin && 'margin: 0 1rem 0 0'};
  ${({ loading }) =>
    loading &&
    `
    transform: scale(1);
    opacity: 1;
  `};
`;

export const TextField = ({
  margin = 'normal',
  width,
  InputLabelProps = {
    shrink: true,
  },
  readOnly,
  disabled,
  InputProps,
  loading,
  select,
  test_id,
  inputProps,
  ...rest
}: ITextFieldProps) => (
  <StyledTextField
    sx={{
      width,
    }}
    margin={margin}
    InputLabelProps={InputLabelProps}
    disabled={disabled || readOnly}
    readOnly={readOnly}
    select={select}
    InputProps={{
      ...InputProps,
      inputProps: {
        ...inputProps,
        ...InputProps?.inputProps,
        'data-test': test_id,
      },
      endAdornment: (
        <>
          <LoadingBox loading={loading} margin={select}>
            <Progress type="circular" size={20} />
          </LoadingBox>
          {InputProps?.endAdornment}
        </>
      ),
    }}
    {...rest}
  />
);
