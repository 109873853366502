import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useRootSlice } from 'app/containers/Root/slice';
import { selectLastPermissionSet } from 'app/containers/Root/slice/selectors';

type useErrorNavigateProps = {
  isAssessor: boolean;
  isCrash: boolean;
  questionnaire_id: string | null;
};

export const useErrorNavigate = ({
  questionnaire_id,
  isAssessor,
  isCrash,
}: useErrorNavigateProps) => {
  const navigate = useNavigate();

  const lastPermissionSet = useSelector(selectLastPermissionSet);

  const { actions } = useRootSlice();

  const { clearError } = actions;

  const dispatch = useDispatch();

  const handleNavigate = () => {
    if (isCrash) {
      window.location.reload();
      return;
    }

    if (!lastPermissionSet) {
      return;
    }

    dispatch(clearError());

    if (isAssessor) {
      navigate('/assessor', { replace: true });

      return;
    }

    navigate(`/vendor/${questionnaire_id}`, {
      replace: true,
    });
  };

  return { handleNavigate };
};
