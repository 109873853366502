import React, { FC } from 'react';

import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material';

const StyledDialog = styled(MUIDialog)`
  .MuiDialog-paper {
    border-radius: 5px;
  }
`;

export const Dialog: FC<DialogProps> = props => {
  return <StyledDialog {...props} />;
};
