import React from 'react';

import { ErrorPage } from 'app/components/Errors/ErrorPage';

export const Forbidden = () => {
  return (
    <ErrorPage
      title="No access"
      subtitle="Our killer security measures detected that you don't have access to this page."
      iconName="no_access"
    />
  );
};
